import React, { useEffect, useState } from 'react'
import { Table, Popconfirm, Button, Select } from 'antd'
import { Main } from '../../../components'
import * as actions from '../actions'
import { connect } from 'react-redux'
import { UpdateRebatesCampaign } from '../components'
import CreateRebatesCampaign from '../components/CreateRebatesCampaign'
import RebatesCampaignStat from '../components/RebatesCampaignStat'
import * as sitesActions from '../../Sites/actions'
import StartCampaign from '../components/StartCampaign'

const RebatesCampaignsList = ({
  setRebatesCampaignsList,
  total,
  rebatesCampaignsList,
  removeRebatesCampaign,
  compaignId,
  compaigns,
  sites,
  setSitesList,
}) => {
  const [pageSize, setPageSize] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)
  const [crmType, setCrmType] = useState(
    window.localStorage.getItem('crm') && JSON.parse(window.localStorage.getItem('crm')).value
      ? JSON.parse(window.localStorage.getItem('crm')).value
      : 'response',
  )
  const [siteId, setSiteId] = useState(
    window.localStorage.getItem('siteId') && JSON.parse(window.localStorage.getItem('siteId')).value
      ? JSON.parse(window.localStorage.getItem('siteId')).value
      : 'all',
  )

  useEffect(() => {
    const campaignItem = JSON.parse(window.localStorage.getItem('campaign'))
    if (campaignItem !== '' && campaignItem) {
      if (siteId !== 'all') {
        setRebatesCampaignsList({
          page: pageNumber,
          size: pageSize,
          filters: { campaignId: +campaignItem.id, siteId: +siteId },
        })
      } else {
        setRebatesCampaignsList({
          page: pageNumber,
          size: pageSize,
          filters: { campaignId: +campaignItem.id },
        })
      }
      setSitesList({ page: 1, size: 1000, filters: { campaignId: +campaignItem.id } })
    } else {
      if (siteId !== 'all') {
        setRebatesCampaignsList({
          page: pageNumber,
          size: pageSize,
          filters: { site_id: siteId },
        })
      } else {
        setRebatesCampaignsList({
          page: pageNumber,
          size: pageSize,
          filters: {},
        })
      }
      setSitesList({ page: 1, size: 1000, site_id: siteId })
    }
  }, [pageSize, pageNumber, compaignId, siteId])

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'MID',
      dataIndex: 'mid',
      key: 'mid',
    },
    {
      title: 'ProductID',
      dataIndex: 'productId',
      key: 'productId',
    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      render: (_, record) => (
        <div>
          {record.active === true ? (
            <div className='status_button runTask_status-button'>Running</div>
          ) : (
            <div className='stopTask_status-button status_button'>Stopped</div>
          )}
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: '',
      render: (_, record) => (
        <>
          <StartCampaign record={record} />
          <UpdateRebatesCampaign record={record} reloadList={reloadList} />
          <RebatesCampaignStat record={record} />
          <Popconfirm
            title='Are you sure delete this RebatesCampaign?'
            onConfirm={e => doConfirm(record.id, e)}
            onCancel={e => e?.stopPropagation()}
            okText='Yes'
            cancelText='No'
          >
            <i className='zmdi zmdi-delete zmdi-hc-2x txt-danger users-list-action_item' href='/#' title='Delete'></i>
          </Popconfirm>
        </>
      ),
    },
  ]

  const doConfirm = (rebatesCampaignId, e) => {
    e && e.stopPropagation()
    removeRebatesCampaign(rebatesCampaignId)
    const campaignItem = JSON.parse(window.localStorage.getItem('campaign'))
    const compaignId = campaignItem.id
    if (compaignId !== '') {
      setRebatesCampaignsList({
        page: pageNumber,
        size: pageSize,
        filters: { campaignId: +compaignId },
      })
    } else {
      setRebatesCampaignsList({ page: pageNumber, size: pageSize, filters: {} })
    }
  }

  const reloadList = () => {
    const campaignItem = JSON.parse(window.localStorage.getItem('campaign'))
    const compaignId = campaignItem.id
    if (compaignId !== '') {
      setRebatesCampaignsList({
        page: pageNumber,
        size: pageSize,
        filters: { campaignId: +compaignId },
      })
    } else {
      setRebatesCampaignsList({ page: pageNumber, size: pageSize, filters: {} })
    }
  }

  const changePaginataion = data => {
    const { current, pageSize: paginationSize } = data
    setPageSize(paginationSize)
    setPageNumber(current)
  }
  console.log(pageNumber, pageSize)

  return (
    <Main>
      <h4 className='txt-dart'>RebatesCampaigns</h4>
      <div>
        <CreateRebatesCampaign reloadList={reloadList} />
        <Select
          className='task_filter_item'
          onSelect={value => setSiteId(value)}
          placeholder='select site'
          showSearch={true}
        >
          <Select.Option value='all'>All sites</Select.Option>
          {sites.map((site, key) => {
            return (
              <Select.Option key={key} value={site.externalId}>
                {site.externalId} - {site.name}
              </Select.Option>
            )
          })}
        </Select>
      </div>
      <Table
        columns={columns}
        dataSource={rebatesCampaignsList}
        bordered
        onChange={changePaginataion}
        rowKey={record => record.id}
        scroll={{ x: 768, y: 10000 }}
        pagination={{
          showSizeChanger: true,
          pageSize,
          current: pageNumber,
          total,
        }}
      />
    </Main>
  )
}

const mapStateToProps = store => ({
  rebatesCampaignsList: store.rebatesCampaigns.list,
  total: store.rebatesCampaigns.total,
  sites: store.sites.sitesForSelect,
  compaigns: store.companies.list,
  compaignId: store.companies.compaign,
})

const mapDispatchToProps = {
  setRebatesCampaignsList: actions.setRebatesCampaignsList,
  removeRebatesCampaign: actions.removeRebatesCampaign,
  setSitesList: sitesActions.fetchSitesList,
}

export default connect(mapStateToProps, mapDispatchToProps)(RebatesCampaignsList)
