import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Table, Popconfirm, Input, Button, Space, Modal, Select, Tabs } from 'antd'
import { Main } from '../../../components'
import { AddNewProcessors, UpdateProcessor } from '../components'
import { UpdateProcessors } from '../components'
import * as actions from '../actions'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import AddCsv from '../components/AddCsv'

const ProcessorsListPage = ({ processors, total, compaignId, compaigns, fetchProcessorsList, removeProcessor }) => {
  const [pageSize, setPageSize] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)
  const [visible, setVisible] = useState(false)
  const [crmType, setCrmType] = useState(
    window.localStorage.getItem('crm') && JSON.parse(window.localStorage.getItem('crm')).value
      ? JSON.parse(window.localStorage.getItem('crm')).value
      : 'response',
  )

  const [state, setState] = useState({
    searchText: '',
    searchedColumn: '',
  })

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    })
  }

  const handleReset = clearFilters => {
    clearFilters()
    setState({ searchText: '' })
  }

  useEffect(() => {
    const campaignItem = JSON.parse(window.localStorage.getItem('campaign'))
    if (campaignItem !== '' && campaignItem) {
      fetchProcessorsList({
        page: pageNumber,
        size: pageSize,
        crmType: crmType,
        filters: { campaignId: campaignItem.id },
      })
    } else {
      fetchProcessorsList({
        page: pageNumber,
        size: pageSize,
        crmType: crmType,
        filters: {},
      })
    }
  }, [pageSize, pageNumber, compaignId])

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({ closeDropdown: false })
              setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              })
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    render: text =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const columns = [
    {
      title: 'ID',
      dataIndex: 'externalId',
      key: 'externalId',
      ...getColumnSearchProps('externalId'),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Compaign Name',
      dataIndex: 'campaignId',
      key: 'campaignId',
      render: record => {
        let name = ''
        // eslint-disable-next-line array-callback-return
        compaigns.map(item => {
          if (+record === +item.id) {
            name = item.name
          }
        })
        return (
          <div>
            {name} ({record})
          </div>
        )
      },
    },
    {
      title: 'Monthly Limit',
      dataIndex: 'limit',
      key: 'limit',
      render: record => {
        record = (+record).toFixed(2).replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1,')
        return <div>{record}</div>
      },
    },
    {
      title: 'Used Limit',
      dataIndex: 'percentageUsed',
      key: 'percentageUsed',
      render: record => <>{(record * 100).toFixed(0)}%</>,
    },
    {
      title: 'DailyCap',
      dataIndex: 'dailyCap',
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'x',
      render: record => (
        <>
          <UpdateProcessor record={record} />

          <Popconfirm
            title='Are you sure delete this user?'
            onConfirm={e => doConfirm(record.id, e)}
            onCancel={e => e?.stopPropagation()}
            okText='Yes'
            cancelText='No'
          >
            <i className='zmdi zmdi-delete zmdi-hc-2x txt-danger users-list-action_item' href='/#' title='Delete'></i>
          </Popconfirm>
        </>
      ),
    },
  ]

  const doConfirm = async (processorId, e) => {
    e && e.stopPropagation()
    removeProcessor(processorId)
  }

  const { TabPane } = Tabs
  const { Option } = Select

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  function callback(key) {
    // console.log(key)
  }

  const changePaginataion = data => {
    const { current, pageSize: paginationSize } = data
    setPageSize(paginationSize)
    setPageNumber(current)
  }

  const companyChange = compaignId => {
    if (compaignId !== '') {
      fetchProcessorsList({
        page: pageNumber,
        size: pageSize,
        crmType: crmType,
        filters: { campaignId: +compaignId },
      })
    } else {
      fetchProcessorsList({ page: pageNumber, size: pageSize, crmType: crmType, filters: {} })
    }
  }

  return (
    <Main>
      <h4 className='processor_list_page-title'>Processors list</h4>

      <div>
        <Button type='primary' onClick={showModal} className='add-new-site_button'>
          Import Processors
        </Button>

        <UpdateProcessors />

        <Modal
          className='customer_modal'
          title='Import Processors for'
          visible={visible}
          onCancel={handleCancel}
          footer={null}
          width='40%'
        >
          <AddNewProcessors onCancel={handleCancel} />
        </Modal>
      </div>
      <AddCsv />

      <Tabs onChange={callback} type='card'>
        <TabPane tab='Credit Card' key='1'>
          <Table
            columns={columns}
            dataSource={processors}
            bordered
            onChange={changePaginataion}
            scroll={{ x: 768, y: 10000 }}
            rowKey={record => record.id}
            pagination={{
              showSizeChanger: true,
              pageSize,
              current: pageNumber,
              total,
            }}
          />
        </TabPane>
      </Tabs>
    </Main>
  )
}

const mapStateToProps = store => ({
  processors: store.processors.list,
  total: store.processors.total,
  compaigns: store.companies.list,
  compaignId: store.companies.compaign,
})

const mapDispatchToProps = {
  fetchProcessorsList: actions.fetchProcessorsList,
  removeProcessor: actions.removeProcessor,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcessorsListPage)
