import axios from '../../utils/axios'
import settings from '../../settings'
import types from './types'
import { guardFromErrors, extractErrorInfo } from '../../utils/graphqlHelper'
import { errorNotification, successNotification } from '../../utils/notificationHelper'

const REMOVE_COMPANY = `
  mutation RemoveCampaign($campaignId: ID!) {
    removeCampaign(campaignId: $campaignId)
  }
`

const ADD_USER_TO_CAMPAIGN = `
  mutation setUserCampaign($campaignId: ID!, $userId: ID!) {
    setUserCampaign(campaignId: $campaignId, userId: $userId)
  }
`

const REMOVE_USER_TO_CAMPAIGN = `
  mutation removeUserFromCampaign($campaignId: ID!, $userId: ID!) {
    removeUserFromCampaign(campaignId: $campaignId, userId: $userId)
  }
`

const UPDATE_COMPANY = `
  mutation updateCampaign ($campaignId: ID!, $name: String!, $guid: String, $paayKey: String,$user_id: String, $user_password: String, $apiURL: String, $chargeDelay: String, $delayAttempt: String, $rebatesKey: String, $descriptor: String) {
    updateCampaign(campaignId: $campaignId, name: $name, guid: $guid, paayKey: $paayKey, user_id: $user_id, user_password: $user_password, apiURL: $apiURL,chargeDelay: $chargeDelay, delayAttempt: $delayAttempt,rebatesKey:$rebatesKey, descriptor: $descriptor)
  }
`

const FETCH_COMPANIES_LIST = `
query campaignsListPage($page: Int, $size: Int, $crmType: String, $filters: CampaignsFilters!) {
  campaignsListPage(page: $page, size: $size, crmType: $crmType, filters: $filters) {
    items {
      id
      name
      guid
      user_id
      user_password
      chargeDelay
      delayAttempt
      userId
      paayKey
      rebatesKey
      apiURL
      descriptor
    }
    total
  }
}
`

const FETCH_COMPANIES_USERS_LIST = `
query userCampaignsListPage($page: Int, $size: Int, $crmType: String, $filters: UserCampaignsFilters!) {
  userCampaignsListPage(page: $page, size: $size, crmType: $crmType, filters: $filters) {
    items {
      userId
      campaignId
    }
    total
  }
}
`

const CREATE_COMPANY = `
  mutation createCampaign ($name: String!, $guid: String, $user_id: String, $user_password: String, $paayKey: String, $crmType: String, $apiURL: String,$chargeDelay: String,$delayAttempt: String,$rebatesKey: String, $descriptor: String) {
    createCampaign(name: $name, guid: $guid, user_id: $user_id, user_password: $user_password, paayKey: $paayKey, crmType: $crmType, apiURL: $apiURL,chargeDelay: $chargeDelay, delayAttempt: $delayAttempt,rebatesKey:$rebatesKey,  descriptor: $descriptor) {
      id
      name
      guid
      user_id
      user_password
      apiURL
      descriptor
      chargeDelay
      delayAttempt
      userId
      createdAt
      updatedAt
    }
  }
`

const FETCH_COMPANIES_FOR_OPTIONS = `
  query fetchCampaignsForOptions {
    campaignsForOptions {
      id
      name
    }
  }
`

//fetchCompaniesUsersList
export const fetchCompaniesUsersList =
  ({ page, size, crmType, filters = {} }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: FETCH_COMPANIES_USERS_LIST,
        variables: { page, size, crmType, filters },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { userCampaignsListPage } = data
      if (!userCampaignsListPage) {
        throw new Error("Can't fetch companies")
      }
      dispatch({
        type: types.SET_COMPANIES_USERS_LIST,
        data: userCampaignsListPage,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      // const errorMessage = extractErrorInfo(errors)
      // dispatch(errorNotification(errorMessage))
    }
  }

//set campaign id
export const setCompaignId = campaign => async dispatch => {
  try {
    window.localStorage.setItem('campaign', JSON.stringify(campaign))
    dispatch({
      type: types.SET_CAMPAIGN_ID,
      data: campaign,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

// createCompany
export const createCompany = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: CREATE_COMPANY,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createCampaign } = data
    if (!createCampaign) {
      throw new Error("Can't create company")
    }
    dispatch(successNotification('Company created successful'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

// addUserToCampaign
export const addUserFromCampaign =
  ({ userId, campaignId }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: ADD_USER_TO_CAMPAIGN,
        variables: { userId, campaignId },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { setUserCampaign } = data
      if (!setUserCampaign) {
        throw new Error("Can't create company")
      }
      dispatch(successNotification('the operation was completed successfully'))

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }

// removeUserToCampaign
export const removeUserFromCampaign =
  ({ userId, campaignId }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: REMOVE_USER_TO_CAMPAIGN,
        variables: { userId, campaignId },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { removeUserFromCampaign } = data
      if (!removeUserFromCampaign) {
        throw new Error("Can't completed operation")
      }
      dispatch(successNotification('the operation was completed successfully'))

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }

//fetchCompaniesForOptions
export const fetchCompaniesForOptions = () => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })
    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: FETCH_COMPANIES_FOR_OPTIONS,
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { campaignsForOptions } = data
    if (!campaignsForOptions) {
      throw new Error("Can't fetch companies")
    }
    dispatch({
      type: types.SET_COMPANIES_FOR_OPTIONS,
      data: campaignsForOptions,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

//removeCompany
export const removeCompany = campaignId => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })
    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: REMOVE_COMPANY,
      variables: { campaignId },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removeCampaign } = data
    if (!removeCampaign) {
      throw new Error("Can't remove company")
    }
    dispatch({
      type: types.REMOVE_COMPANY,
      data: campaignId,
    })

    dispatch(successNotification('Company removed successful'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

//updateCompany
export const updateCompany = formData => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: UPDATE_COMPANY,
      variables: { ...formData },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateCampaign } = data
    if (!updateCampaign) {
      throw new Error("Can't update company")
    }

    dispatch(successNotification('Company update successful'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

//fetchCompaniesList
export const fetchCompaniesList =
  ({ page, size, crmType, filters = {} }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: FETCH_COMPANIES_LIST,
        variables: { page, size, crmType, filters },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { campaignsListPage } = data
      if (!campaignsListPage) {
        throw new Error("Can't get companies")
      }
      dispatch({
        type: types.SET_COMPANIES_LIST,
        data: campaignsListPage,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }

export const setUserId = userId => async dispatch => {
  try {
    dispatch({
      type: types.SET_USER_ID,
      data: userId,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}
