import React, { useEffect, useState } from 'react'
import { Select, Form, Col, Input, Checkbox, InputNumber, Row, TimePicker, Button } from 'antd'
import { Main } from '../../../components'
import * as actions from '../actions'
import { connect } from 'react-redux'
import { golanceCampaign } from '../actions'
import dayjs from 'dayjs'

const RebatesCampaignsGolance = ({ getGolanceCampaign, updateGolanceCampaign }) => {
  const [campaign, setCampaign] = useState({})
  const [isRandomPrice, setIsRandomPrice] = useState(false)
  const [form] = Form.useForm()
  const getInfo = async () => {
    console.log('try get')
    const result = await getGolanceCampaign({
      action: 'get_rebatesCampaign_info',
      id: 0,
    })
    if (result?.id) setCampaign(result)
  }
  useEffect(() => {
    getInfo()
  }, [])
  useEffect(() => {
    setIsRandomPrice(campaign.isRandomPrice)
  }, [campaign])

  const onFinishHandler = async values => {
    values.startTime = values.startTime.format('HH:mm:ss')
    values.endTime = values.endTime.format('HH:mm:ss')
    await updateGolanceCampaign({
      action: 'edit_rebatesCampaign',
      id: campaign.id,
      ...values,
      p_price: values.p_price ? values.p_price : campaign.p_price,
      minPrice: values.minPrice ? values.minPrice : campaign.minPrice,
      maxPrice: values.maxPrice ? values.maxPrice : campaign.maxPrice,
      randomPrice: isRandomPrice ? 'Y' : 'N',
    })
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  const campaignAction = async () => {
    if (!campaign?.active) {
      await updateGolanceCampaign({
        action: 'run_campaign',
        id: campaign.id,
      })
    } else {
      await updateGolanceCampaign({
        action: 'stop_campaign',
        id: campaign.id,
      })
    }
    window.location.reload()
  }

  if (!campaign?.id) {
    return (
      <Main>
        <h4 className='txt-dart'>Golance Campaign</h4>
        Cant load info
      </Main>
    )
  }

  return (
    <Main>
      <h2 className='txt-dart'>Golance Campaign</h2>
      <Row gutter={16}>
        <Col md={12} xs={24}>
          <h3>Info</h3>
          <h4>Transactions Count: {campaign.totalCards}</h4>
          <h4>Transactions Amount: {formatter.format(campaign.totalCardsAmount)}</h4>
          <br />
          <h4>
            Current Status:{' '}
            {campaign.active ? (
              <span style={{ color: 'green' }}>Running</span>
            ) : (
              <span style={{ color: 'red' }}>Stopped</span>
            )}
          </h4>
          <h3>Current Day Stat</h3>
          <h4>Processed Rebatez Transactions Count: {campaign.rebatesTransactoins}</h4>
          <h4>Processed Real Transactions Count: {campaign.realTransactoins}</h4>
          <br />
          <Button type='primary' onClick={campaignAction}>
            {campaign.active ? 'Stop' : 'Run'}
          </Button>
        </Col>
        <Col md={12} xs={24}>
          <Form
            hideRequiredMark
            onFinish={onFinishHandler}
            form={form}
            labelCol={{ span: 24, offset: 0 }}
            wrapperCol={{ span: 24, offset: 0 }}
          >
            <Row gutter={8}>
              <Col sm={12}>
                <Form.Item name='p_id' label='Product Id' initialValue={campaign.p_id}>
                  <Input />
                </Form.Item>
              </Col>
              <Col sm={24}>
                <Form.Item
                  labelCol={{ span: 'auto', offset: 0 }}
                  wrapperCol={{ span: 'auto', offset: 0 }}
                  name='isRandomPrice'
                  label='Use Random Price'
                  valuePropName={'checkbox'}
                  initialValue={campaign?.isRandomPrice}
                >
                  <Checkbox checked={isRandomPrice} onChange={() => setIsRandomPrice(!isRandomPrice)} />
                </Form.Item>
              </Col>
              {!isRandomPrice && (
                <Col sm={12}>
                  <Form.Item
                    name='p_price'
                    initialValue={campaign.p_price}
                    label='Price'
                    rules={[{ required: true, message: 'Please input Price!' }]}
                  >
                    <InputNumber min={0} precision={2} />
                  </Form.Item>
                </Col>
              )}
              {isRandomPrice && (
                <Col sm={12}>
                  <Form.Item
                    name='minPrice'
                    initialValue={campaign.minPrice}
                    label='Minimal Price'
                    rules={[{ required: true, message: 'Please input Minimal Price!' }]}
                  >
                    <InputNumber min={0} precision={2} />
                  </Form.Item>
                </Col>
              )}
              {isRandomPrice && (
                <Col sm={12}>
                  <Form.Item
                    name='maxPrice'
                    initialValue={campaign.maxPrice}
                    label='Maximum Price'
                    rules={[{ required: true, message: 'Please input Maximum Price!' }]}
                  >
                    <InputNumber min={0} precision={2} />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row>
              <Col sm={12}>
                <Form.Item
                  name='startTime'
                  initialValue={dayjs('2024-04-04 ' + campaign.startTime)}
                  label='Start Time'
                  rules={[{ required: true, message: 'Please Choose Start Time!' }]}
                >
                  <TimePicker allowClear={false} format={'HH:mm:ss'} changeOnScroll={true}></TimePicker>
                </Form.Item>
              </Col>
              <Col sm={12}>
                <Form.Item
                  name='endTime'
                  initialValue={dayjs('2024-04-04 ' + campaign.endTime)}
                  label='End Time'
                  rules={[{ required: true, message: 'Please input End Time!' }]}
                >
                  <TimePicker allowClear={false} format={'HH:mm:ss'} changeOnScroll={true}></TimePicker>
                </Form.Item>
              </Col>
              <Col sm={12}>
                <Form.Item
                  name='mc_count'
                  initialValue={campaign.mc_count}
                  label='MC Count'
                  rules={[{ required: true, message: 'Please input MC Count!' }]}
                >
                  <InputNumber min={0} precision={0} />
                </Form.Item>
              </Col>
              <Col sm={12}>
                <Form.Item
                  name='visa_count'
                  initialValue={campaign.visa_count}
                  label='Visa Count'
                  rules={[{ required: true, message: 'Please input Visa Count!' }]}
                >
                  <InputNumber min={0} precision={0} />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Button htmlType={'submit'} type={'primary'}>
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Main>
  )
}

const mapDispatchToProps = {
  getGolanceCampaign: actions.golanceCampaign,
  updateGolanceCampaign: actions.updateGolanceCampaign,
}

export default connect(null, mapDispatchToProps)(RebatesCampaignsGolance)
