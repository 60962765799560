import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import './assets/css/vendor/jasny-bootstrap.min.css'
import './assets/css/style.css'
import 'react-redux-notify/dist/ReactReduxNotify.css'
import { Notify } from 'react-redux-notify'
import { Loader } from './components'
import {
  Login,
  Signup,
  ClientsPages,
  CompaniesPages,
  // CustomersPages,
  SitesPages,
  ProcessorsPages,
  ProductsPages,
  TasksPages,
  GroupsPages,
  CorparationsPages,
  RulesPages,
  PixelsPages,
  ScrubsPages,
  RebatesCampaigns,
} from './pages'
import { RebatesCampaignList } from './pages/RebatesCampaigns/pages'

const AdminRoute = ({ component: Component, props, ...rest }) => {
  const role = props.roles.find(item => item === 'admin') || 'user'
  return (
    <Route
      {...rest}
      render={propsNext =>
        !!props.token && role === 'admin' ? <Component {...propsNext} /> : <Redirect to='/login' />
      }
    />
  )
}

const PrivateRoute = ({ component: Component, token, ...rest }) => (
  <Route {...rest} render={props => (!!token ? <Component {...props} /> : <Redirect to='/login' />)} />
)

const NonAuthRoute = ({ component: Component, me, token, ...rest }) => {
  return <Route {...rest} render={props => (!!token ? <Redirect to='/' /> : <Component {...props} />)} />
}

const App = props => {
  return (
    <div className='App'>
      <Notify />
      <Loader />
      <Switch>
        <PrivateRoute
          exact
          path={`${process.env.PUBLIC_URL}/`}
          token={props.token}
          component={TasksPages.TasksListPage}
        />
        {/* <PrivateRoute
          exact
          path="/customers"
          token={props.token}
          component={CustomersPages.CustomersList}
        /> */}
        <AdminRoute path={`${process.env.PUBLIC_URL}/add-client`} props={props} component={ClientsPages.AddClient} />
        <AdminRoute
          path={`${process.env.PUBLIC_URL}/clients-list`}
          props={props}
          component={ClientsPages.ClientsList}
        />
        <PrivateRoute
          path={`${process.env.PUBLIC_URL}/clientInfo`}
          token={props.token}
          component={ClientsPages.ClientInfo}
        />
        <PrivateRoute
          path={`${process.env.PUBLIC_URL}/companies`}
          token={props.token}
          role={props.role}
          component={CompaniesPages.CompaniesList}
        />
        <NonAuthRoute path={`${process.env.PUBLIC_URL}/login`} token={props.token} me={props.me} component={Login} />
        <NonAuthRoute path={`${process.env.PUBLIC_URL}/signup`} token={props.token} component={Signup} />
        <PrivateRoute
          path={`${process.env.PUBLIC_URL}/site-list`}
          token={props.token}
          component={SitesPages.SitesList}
        />
        <PrivateRoute path={`${process.env.PUBLIC_URL}/add-site`} token={props.token} component={SitesPages.AddSite} />
        <PrivateRoute
          path={`${process.env.PUBLIC_URL}/processors`}
          token={props.token}
          component={ProcessorsPages.ProcessorsListPage}
        />
        <PrivateRoute
          path={`${process.env.PUBLIC_URL}/products`}
          token={props.token}
          component={ProductsPages.ProductsList}
        />
        <PrivateRoute
          path={`${process.env.PUBLIC_URL}/pixels`}
          token={props.token}
          component={PixelsPages.PixelsList}
        />
        <PrivateRoute path={`${process.env.PUBLIC_URL}/scrub`} token={props.token} component={ScrubsPages.ScrubsList} />
        {/* <PrivateRoute
          path="/tasks"
          token={props.token}
          component={TasksPages.TasksListPage}
        /> */}
        <PrivateRoute
          path={`${process.env.PUBLIC_URL}/corporations`}
          token={props.token}
          component={CorparationsPages.GroupsListPage}
        />
        <PrivateRoute
          path={`${process.env.PUBLIC_URL}/groups`}
          token={props.token}
          component={GroupsPages.GroupsListPage}
        />
        <PrivateRoute
          path={`${process.env.PUBLIC_URL}/rules:groupId?`}
          token={props.token}
          component={RulesPages.RulesListPage}
        />
        <PrivateRoute
          path={`${process.env.PUBLIC_URL}/rebatesCampaigns`}
          token={props.token}
          component={RebatesCampaigns.RebatesCampaignList}
        />
        <PrivateRoute
          path={`${process.env.PUBLIC_URL}/rebatesCampaignGolance`}
          token={props.token}
          component={RebatesCampaigns.RebatesCampaignGolance}
        />
      </Switch>
    </div>
  )
}

const mapStateToProps = store => ({
  me: store.login,
})

export default connect(mapStateToProps, null)(App)
