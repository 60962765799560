import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Drawer, Input, Form, InputNumber, Select, Modal, Col, Row, Checkbox, Table, Popconfirm } from 'antd'
import * as actions from '../actions'
import * as compaignsActions from '../../Companies/actions'
import * as sitesActions from '../../Sites/actions'
import * as productsActions from '../../Products/actions'
import * as processorsActions from '../../Processors/actions'
import { CopyFilled, DeleteFilled } from '@ant-design/icons'
import CreateTimeWindow from './CreateTimeWindow'
import EditTimeWindow from './EditTimeWindow'
import EditAttempt from './EditAttempt'
import AddAttempt from './AddAttempt'

const CreateRebatesCampaign = ({
  campaigns,
  fetchCompaniesList,
  setRebatesCampaignsList,
  createRebatesCampaign,
  sites,
  processors,
  products,
  setSitesListForSelect,
  setProductsList,
  fetchProcessorsList,
  reloadList,
}) => {
  const [isVisible, setVisible] = useState(false)
  const [campaignId, setCampaignId] = useState(0)
  const [isRandomPrice, setIsRandomPrice] = useState(false)
  const [useAttempts, setUseAttempts] = useState(false)
  const [timeWindows, setTimeWindows] = useState([])
  const [attempts, setAttempts] = useState([])
  const [form] = Form.useForm()
  const [crmType, setCrmType] = useState(
    window.localStorage.getItem('crm') && JSON.parse(window.localStorage.getItem('crm')).value
      ? JSON.parse(window.localStorage.getItem('crm')).value
      : 'response',
  )
  useEffect(() => {
    fetchCompaniesList({ page: 1, size: 100, crmType: crmType })
  }, [fetchCompaniesList])
  useEffect(() => {
    setSitesListForSelect({ page: 1, size: 1000, filters: { campaignId: +campaignId } })
    setProductsList({ page: 1, size: 1000, filters: { campaignId: +campaignId } })
    fetchProcessorsList({ page: 1, size: 1000, filters: { campaignId: +campaignId } })
  }, [campaignId])

  const attemptsColumns = [
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Delay in Minutes',
      dataIndex: 'delay',
      key: 'delay',
    },
    {
      title: 'Actions',
      dataIndex: 'attemptsActions',
      key: 'attemptsActions',
      render: (_, record) => (
        <div>
          <EditAttempt attempt={record} editAttempt={editAttempt} />
          <Popconfirm
            title='Remove Attempt'
            description='Confirm Attempt Remove'
            onConfirm={() => deleteAttempt(record)}
            okText='Delete'
            cancelText='Cancel'
          >
            <DeleteFilled />
          </Popconfirm>
        </div>
      ),
    },
  ]

  const timeWindowsColumns = [
    {
      title: 'Day',
      dataIndex: 'day',
      key: 'day',
      render: (_, record, index) => index + 1,
    },
    {
      title: 'Start Time',
      dataIndex: 'startTime',
      key: 'startTime',
    },
    {
      title: 'End Time',
      dataIndex: 'endTime',
      key: 'endTime',
    },
    {
      title: 'MC Count',
      dataIndex: 'mcCount',
      key: 'mcCount',
    },
    {
      title: 'VI Count',
      dataIndex: 'visaCount',
      key: 'visaCount',
    },
    {
      title: 'Actions',
      dataIndex: 'timeWindowsActions',
      key: 'timeWindowsActions',
      render: (_, record) => (
        <div>
          <EditTimeWindow window={record} editTimeWindow={editTimeWindow} />
          <CopyFilled onClick={() => copyWindow(record)} />
          <Popconfirm
            title='Remove Window'
            description='Confrim Window Remove'
            onConfirm={() => deleteTimeWindow(record)}
            okText='Delete'
            cancelText='Cancel'
          >
            <DeleteFilled />
          </Popconfirm>
        </div>
      ),
    },
  ]
  const deleteTimeWindow = window => {
    const newTimeWindows = timeWindows
      .filter(timeWindow => timeWindow !== window)
      .map((timeWindow, index) => {
        return {
          ...timeWindow,
          day: index,
        }
      })
    setTimeWindows(newTimeWindows)
  }
  const copyWindow = window => {
    const newWindow = {}
    newWindow.startTime = window.startTime
    newWindow.endTime = window.endTime
    newWindow.mcCount = window.mcCount
    newWindow.visaCount = window.visaCount
    //window.key = timeWindows.length
    newWindow.day = timeWindows.length
    setTimeWindows([...timeWindows, newWindow])
  }
  const addTimeWindow = window => {
    window.startTime = window.startTime.format('HH:mm:ss')
    window.endTime = window.endTime.format('HH:mm:ss')
    //window.totalCount = window.mcCount + window.visaCount
    //window.key = timeWindows.length
    window.day = timeWindows.length
    setTimeWindows([...timeWindows, window])
  }
  const editTimeWindow = () => {
    setTimeWindows([
      ...timeWindows.map((timeWindow, index) => {
        return {
          ...timeWindow,
          day: index,
        }
      }),
    ])
  }

  const deleteAttempt = attempt => {
    const newAttempts = attempts.filter(campaignAttempt => campaignAttempt !== attempt)
    setAttempts(newAttempts)
  }
  const addAttempt = attempt => {
    attempt.key = attempts.length
    const newAttempts = [...attempts, attempt]
    setAttempts(newAttempts.sort((a, b) => a.delay - b.delay))
  }
  const editAttempt = () => {
    setAttempts([...attempts.sort((a, b) => a.delay - b.delay)])
  }

  const onFinishHandler = async values => {
    const campaignItem = JSON.parse(window.localStorage.getItem('campaign'))
    await createRebatesCampaign({
      name: values.name,
      active: false,
      campaignId: +values.campaignId,
      siteId: +values.siteId,
      productId: +values.productId,
      shippingId: +values.shippingId,
      mid: +values.mid,
      repeatDays: values.repeatDays,
      isRandomPrice: isRandomPrice,
      price: +values.price,
      minPrice: +values.minPrice,
      maxPrice: +values.maxPrice,
      timeWindows: timeWindows,
      forceIp: values.forceIp,
      merchantId: values.merchantId,
      useAttempts: useAttempts,
      attempts: attempts.map(attempt => {
        return {
          price: attempt.price,
          delay: attempt.delay,
        }
      }),
    })
    reloadList()
    setVisible(false)
    form.resetFields()
  }

  return (
    <>
      <Button
        type='primary'
        onClick={() => {
          setVisible(true)
          form.resetFields()
        }}
        className='add-new-site_button'
      >
        Add Rebates Campaign
      </Button>
      <Modal
        title='Create Rebates Campaign'
        className={'rebatesCampaign_popup'}
        onClose={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        visible={isVisible}
        width={'90%'}
        footer={[
          <Button key='back' onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button key='submit' type='primary' onClick={() => form.submit()}>
            Create
          </Button>,
        ]}
      >
        <Form
          hideRequiredMark
          onFinish={onFinishHandler}
          form={form}
          labelCol={{ span: 24, offset: 0 }}
          wrapperCol={{ span: 24, offset: 0 }}
        >
          <Row gutter={8}>
            <Col sm={24}>
              <Form.Item
                name='name'
                initialValue={''}
                label='Name'
                rules={[{ required: true, message: 'Please input Name!' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                name='campaignId'
                label='Select a company'
                rules={[{ required: true, message: 'Select Company!' }]}
              >
                <Select
                  onSelect={value => setCampaignId(value)}
                  showSearch={true}
                  options={campaigns.map(item => {
                    return { value: item.id, label: item.name }
                  })}
                />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                name='siteId'
                label='Select a siteId(campaign)'
                rules={[{ required: true, message: 'Select siteId(campaign)!' }]}
              >
                <Select
                  showSearch={true}
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={[
                    { value: 0, label: 'Unset' },
                    ...sites
                      .sort((a, b) => a.externalId - b.externalId)
                      .map(item => {
                        return { value: item.externalId, label: `(${item.externalId}) ` + item.name }
                      }),
                  ]}
                />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                name='productId'
                label='Select a productId'
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                rules={[{ required: true, message: 'Select productId!' }]}
              >
                <Select
                  showSearch={true}
                  options={[
                    { value: 0, label: 'Unset' },
                    ...products
                      .sort((a, b) => a.externalId - b.externalId)
                      .map(item => {
                        return { value: item.externalId, label: `(${item.externalId}) ` + item.name }
                      }),
                  ]}
                />
              </Form.Item>
            </Col>
            <Col sm={6}>
              <Form.Item initialValue={2} name='shippingId' label='Shipping Id'>
                <InputNumber min={0} />
              </Form.Item>
            </Col>
            <Col sm={6}>
              <Form.Item name='forceIp' label='Force IP Address'>
                <Input />
              </Form.Item>
            </Col>
            <Col sm={6}>
              <Form.Item name='mid' label='Select a MID' rules={[{ required: true, message: 'Select MID!' }]}>
                <Select
                  showSearch={true}
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={[
                    { value: 0, label: 'Unset' },
                    ...processors
                      .sort((a, b) => a.externalId - b.externalId)
                      .map(item => {
                        return { value: item.externalId, label: `(${item.externalId}) ` + item.name }
                      }),
                  ]}
                />
              </Form.Item>
            </Col>
            <Col sm={6}>
              <Form.Item name='merchantId' label='Merchant ID'>
                <Input />
              </Form.Item>
            </Col>
            <Col sm={6}>
              <Form.Item
                name='repeatDays'
                label='Repeat Type'
                rules={[{ required: true, message: 'Select Repeat Type' }]}
              >
                <Select
                  options={[
                    { value: 'last', label: 'Repeat Last' },
                    { value: 'cycle', label: 'Cycle' },
                    { value: 'stopAfter', label: 'Stop After Last Day' },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col sm={24}>
              <Form.Item
                labelCol={{ span: 'auto', offset: 0 }}
                wrapperCol={{ span: 'auto', offset: 0 }}
                name='isRandomPrice'
                label='Use Random Price'
                valuePropName={'checkbox'}
              >
                <Checkbox checked={isRandomPrice} onChange={() => setIsRandomPrice(!isRandomPrice)} />
              </Form.Item>
            </Col>
            {!isRandomPrice && (
              <Col sm={12}>
                <Form.Item
                  name='price'
                  initialValue={0}
                  label='Price'
                  rules={[{ required: true, message: 'Please input Price!' }]}
                >
                  <InputNumber min={0} precision={2} />
                </Form.Item>
              </Col>
            )}
            {isRandomPrice && (
              <Col sm={12}>
                <Form.Item
                  name='minPrice'
                  initialValue={0}
                  label='Minimal Price'
                  rules={[{ required: true, message: 'Please input Minimal Price!' }]}
                >
                  <InputNumber min={0} precision={2} />
                </Form.Item>
              </Col>
            )}
            {isRandomPrice && (
              <Col sm={12}>
                <Form.Item
                  name='maxPrice'
                  initialValue={0}
                  label='Maximum Price'
                  rules={[{ required: true, message: 'Please input Maximum Price!' }]}
                >
                  <InputNumber min={0} precision={2} />
                </Form.Item>
              </Col>
            )}
            {!isRandomPrice && (
              <Col sm={24}>
                <Form.Item
                  labelCol={{ span: 'auto', offset: 0 }}
                  wrapperCol={{ span: 'auto', offset: 0 }}
                  name='useAttempts'
                  label='Use Attempts'
                  valuePropName={'checkbox'}
                >
                  <Checkbox checked={useAttempts} onChange={() => setUseAttempts(!useAttempts)} />
                </Form.Item>
              </Col>
            )}
          </Row>
          {!isRandomPrice && useAttempts && (
            <>
              <AddAttempt addAttempt={addAttempt} />
              <Table dataSource={attempts} columns={attemptsColumns} pagination={false} />
            </>
          )}
          <CreateTimeWindow addTimeWindow={addTimeWindow} />
          <Table
            dataSource={timeWindows}
            columns={timeWindowsColumns}
            pagination={false}
            rowKey={record => record.day}
          />
        </Form>
      </Modal>
    </>
  )
}
const mapStateToProps = store => ({
  campaigns: store.companies.list,
  processors: store.processors.list,
  products: store.products.list,
  sites: store.sites.sitesForSelect,
})
const mapDispatchToProps = {
  setRebatesCampaignsList: actions.setRebatesCampaignsList,
  createRebatesCampaign: actions.createRebatesCampaign,
  fetchCompaniesList: compaignsActions.fetchCompaniesList,
  setSitesListForSelect: sitesActions.fetchSitesListForSelect,
  setProductsList: productsActions.setProductsList,
  fetchProcessorsList: processorsActions.fetchProcessorsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateRebatesCampaign)
