import axios from '../../utils/axios'
import { default as axios2 } from 'axios'
import settings from '../../settings'
import { types } from './types'
import { guardFromErrors, extractErrorInfo } from '../../utils/graphqlHelper'
import { errorNotification, successNotification } from '../../utils/notificationHelper'

const SET_REBATESCAMPAIGNS_LIST = `
query rebatesCampaignsListPage($page: Int, $size: Int, $filters: RebatesCampaignFilters!) {
  rebatesCampaignsListPage(page: $page, size: $size, filters: $filters) {
    items {
      id
      name
      repeatDays
      active
      campaignId
      siteId
      productId
      shippingId
      isRandomPrice
      price
      minPrice
      maxPrice
      mid
      useAttempts
      forceIp
      merchantId
      rebatesAttempts{
        price
        delay
      }
      timeWindows{
        day
        startTime
        endTime
        mcCount
        visaCount
      }
    }
    total
  }
}
`

const REMOVE_REBATESCAMPAIGN = `
  mutation removeRebatesCampaign($rebatesCampaignId: ID!) {
    removeRebatesCampaign(rebatesCampaignId: $rebatesCampaignId)
  }
`

const REBATESCAMPAIGN_STAT = `
  query rebatesCampaign($rebatesCampaignId: ID!,$date: String) {
    rebatesCampaign(rebatesCampaignId: $rebatesCampaignId,date:$date){
      
        total_success
        total_declines
        success
        declines
        declines_logs{
          id
          cc_number
          date
          status
          reason
        }
    }
  }
`

const UPDATE_REBATESCAMPAIGN = `
  mutation updateRebatesCampaign(
  $id: ID!,
  $name: String!,
$repeatDays: String!,
$active: Boolean!,
$campaignId: Int!,
$siteId: Int!,
$productId: Int!,
$shippingId: Int,
$isRandomPrice: Boolean!,
$price: Float,
$minPrice: Float,
$maxPrice: Float,
$mid: Int!,
$useAttempts: Boolean!,
$forceIp: String,
$merchantId: String,
$attempts: [RebatesAttemptsInput]
$timeWindows: [TimeWindowInput]) {
    updateRebatesCampaign(
    id: $id, 
    name: $name, 
    repeatDays: $repeatDays, 
    active: $active, 
    campaignId: $campaignId, 
    siteId: $siteId, 
    productId: $productId,
    shippingId:$shippingId, 
    isRandomPrice: $isRandomPrice, 
    price: $price, 
    minPrice: $minPrice, 
    maxPrice: $maxPrice, 
    mid: $mid, 
    timeWindows:$timeWindows, 
    useAttempts:$useAttempts,
    forceIp:$forceIp,
    merchantId: $merchantId,
    attempts:$attempts,
    )
  }
`

const CREATE_REBATESCAMPAIGN = `
  mutation createRebatesCampaign(
  $name: String!,
$repeatDays: String!,
$active: Boolean!,
$campaignId: Int!,
$siteId: Int!,
$productId: Int!,
$shippingId: Int,
$isRandomPrice: Boolean!,
$price: Float,
$minPrice: Float,
$maxPrice: Float,
$mid: Int!,
$useAttempts: Boolean!,
$forceIp: String,
$merchantId: String,
$attempts: [RebatesAttemptsInput]
$timeWindows: [TimeWindowInput]) {
    createRebatesCampaign(
      name: $name, 
      repeatDays: $repeatDays, 
      active: $active, 
      campaignId: $campaignId, 
      siteId: $siteId, 
      productId: $productId,
      shippingId:$shippingId, 
      isRandomPrice: $isRandomPrice, 
      price: $price, 
      minPrice: $minPrice, 
      maxPrice: $maxPrice, 
      mid: $mid, 
      timeWindows:$timeWindows, 
      useAttempts:$useAttempts,
      forceIp:$forceIp,
      merchantId: $merchantId,
      attempts:$attempts,
    )
  }
`
const UPDATE_STATUS_CAMPAIGN = `
  mutation updateStatus($id: ID!, $action: String!) {
    updateStatus(id: $id, action: $action)
  }
`

export const updateStatusCampaign =
  ({ id, action }) =>
  async dispatch => {
    try {
      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: UPDATE_STATUS_CAMPAIGN,
        variables: { id, action },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { updateStatus } = data
      if (!updateStatus) {
        throw new Error("Can't update status")
      }
      dispatch({
        type: types.SET_REBATESCAMPAIGN_STATUS,
        data: { id, action },
      })
      dispatch(
        successNotification(
          action === 'run_campaign' ? 'Campaign started successful!' : 'Campaign stopped successful!',
        ),
      )

      return updateStatus
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
      return false
    }
  }

//setRebatesCampaignsList
export const setRebatesCampaignsList =
  ({ page, size, filters = {} }) =>
  async dispatch => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
        query: SET_REBATESCAMPAIGNS_LIST,
        variables: { page, size, filters },
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { rebatesCampaignsListPage } = data
      if (!rebatesCampaignsListPage) {
        throw new Error("Can't get rebatesCampaigns!")
      }

      dispatch({
        type: types.SET_REBATESCAMPAIGNS_LIST,
        data: rebatesCampaignsListPage,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }

export const getRebatesCampaignStat = (rebatesCampaignId, date) => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })
    console.log(rebatesCampaignId)
    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: REBATESCAMPAIGN_STAT,
      variables: { rebatesCampaignId, date },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { rebatesCampaign } = data
    if (!rebatesCampaign) {
      throw new Error("Can't get logs!")
    }

    dispatch({
      type: types.GET_REBATESCAMPAIGN_STAT,
      data: rebatesCampaign,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

//remove RebatesCampaign
export const removeRebatesCampaign = rebatesCampaignId => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: REMOVE_REBATESCAMPAIGN,
      variables: { rebatesCampaignId },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removeRebatesCampaign } = data
    if (!removeRebatesCampaign) {
      throw new Error("Can't remove RebatesCampaign")
    }

    dispatch({
      type: types.REMOVE_REBATESCAMPAIGN,
      data: rebatesCampaignId,
    })

    dispatch(successNotification('Remove RebatesCampaign successful'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

//update RebatesCampaign
export const updateRebatesCampaign = args => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })
    console.log(args)
    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: UPDATE_REBATESCAMPAIGN,
      variables: args,
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateRebatesCampaign } = data
    if (!updateRebatesCampaign) {
      throw new Error("Can't update RebatesCampaign!")
    }

    dispatch(successNotification('RebatesCampaign update successful!'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    console.log(errorMessage)
    dispatch(errorNotification(errorMessage))
  }
}

export const createRebatesCampaign = args => async dispatch => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: CREATE_REBATESCAMPAIGN,
      variables: args,
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createRebatesCampaign } = data
    if (!createRebatesCampaign) {
      throw new Error("Can't update RebatesCampaign!")
    }

    dispatch(successNotification('RebatesCampaign update successful!'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

export const golanceCampaign = args => async dispatch => {
  try {
    console.log('test')
    const { data, errors } = await axios2.post('https://dev62.awzdev.com/api_live/2/rebatesCampaign_golance.php', args)
    if (!data) {
      throw new Error('Response body is empty!')
    }
    console.log(data)
    if (!data?.Status) {
      throw new Error("Can't get Campaign")
    }
    //dispatch(successNotification('Campaign updated successful!'))

    return data
  } catch (errors) {
    console.log(errors)
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
    return false
  }
}
export const updateGolanceCampaign = args => async dispatch => {
  try {
    console.log('test')
    const { data, errors } = await axios2.post('https://dev62.awzdev.com/api_live/2/rebatesCampaign_golance.php', args)
    if (!data) {
      throw new Error('Response body is empty!')
    }
    console.log(data)
    if (!data?.Status) {
      throw new Error("Can't update Campaign")
    }
    //dispatch(successNotification('Campaign updated successful!'))

    return data
  } catch (errors) {
    console.log(errors)
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
    return false
  }
}
